<template>
  <v-card width="100%" height="100%" class="rounded-0">
    <v-toolbar color="guacamole">
      <v-toolbar-title>Map Control</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <div style="height: 500px; width: 100%">
        <l-map
          ref="mapcontrolMap"
          v-if="showMap"
          :zoom="zoom"
          :center="center"
          :options="mapOptions"
          style="height: 100%"
          @update:center="centerUpdate"
          @update:zoom="zoomUpdate"
          :key="magicnumber"
        >
        <l-control-layers position="topright"></l-control-layers>

          <l-control-zoom position="bottomright"></l-control-zoom>
          <!-- <l-tile-layer :url="url" :attribution="attribution" /> -->
          <l-tile-layer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base"
            :options="tileoptions"
          />
          <l-marker
            v-for="(marker, index) in markers"
            :lat-lng.sync="marker.position"
            :draggable="marker.draggable"
            :visible="marker.visible"
            @click="removeMarker(index)"
            
            :key="index"
          ></l-marker>
        </l-map>
      </div>
    </v-card-text>

    <v-card-text>
      <!-- START DEBUG -->
      <!-- <v-btn @click.prevent="sendMarker">Send Marker</v-btn>
      <p>Lat: {{ lat }}</p>
      <p>Lng: {{ lng }}</p>
      <p>{{ foobar }}</p>
      <p>{{ markers }}</p> -->
      <!-- <p>{{ foobar }}</p>
      <p>{{ markers }}</p> -->
      <!-- END DEBUG -->
      <v-data-table
        :headers="headers"
        :items="markers"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn block @click.prevent="sendMarker(item)">Confirm Marker</v-btn>
          <v-btn @click.prevent="toggleVis(item)" class="ma-2">
            <v-icon v-if='item.visible'>mdi-eye</v-icon>
            <v-icon v-else>mdi-eye-off</v-icon>
          </v-btn>
          <v-btn @click.prevent="focusMarker(item)" class="ma-2">
            <v-icon>mdi-crosshairs-gps</v-icon>
          </v-btn>
          <v-btn @click.prevent="lockMarker(item)" class="ma-2">
            <v-icon v-if="item.draggable">mdi-lock-open</v-icon>
            <v-icon v-else>mdi-lock</v-icon>
          </v-btn>
          <!-- add state to the button -->
        </template>
      </v-data-table>

    </v-card-text>
  </v-card>
</template>

<script>
import { sync, get, call } from "vuex-pathify";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LControlZoom, LControlLayers } from "vue2-leaflet";
import { getMarkers } from '@/utils/general.js'
import { mapGetters } from 'vuex'
import PassEntryCode from '@/mixins/PassEntrycode.js'

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


export default {
  name: "MapControl",
  mixins: [PassEntryCode],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    LControlLayers
  },
  data: function () {
    return {
      lat: "",
      lng: "",
      zoom: 8.5,
      center: latLng(38.682613, 22.660929),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      showMap: true,
      currentZoom: 11.5,
      currentCenter: this.mapCenter,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
      tileoptions: { useCache: true },
      markers: [],
      newmarkers: [],
      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        },
        {
          name: 'Esri.WorldImagery',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        }
      ],
	  headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Lat',
          value: 'position.lat'
        },
        {
          text: 'Lng',
          value: 'position.lng'
        },
        {
          text: 'actions',
          value: 'actions',
          sortable: false
        }
      ]
    };
  },
  methods: {
    ...call('mapform', [
      'setMarkers'
    ]),
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    addMarker(evt) {
      console.log('just checking')
      const newMarker = {
        position: { lat: evt.latlng.lat, lng: evt.latlng.lng },
        draggable: true,
        visible: true,
      };
      if (this.markers.length > 0) {
        return;
      }
      this.lat = evt.latlng.lat;
      this.lng = evt.latlng.lng;
      this.markers.push(newMarker);
    },
    removeMarker(index) {
      this.markers.splice(index, 1);
      // this.foobar.splice(index, 1)
      this.lat = "";
      this.lng = "";
    },
    setMarkerCoordinates(evt) {
      this.lat = parseFloat(evt.lat).toFixed(8);
      this.lng = parseFloat(evt.lng).toFixed(8);
    },
    sendMarker (item) {

      let index = this.markers.indexOf(item)
      console.log("and the index is: " + index)
      
      this.$root.$emit("marker-control-complete", this.markers[index])
      // if (this.markers.length > 0) {
      //   this.$root.$emit("marker-control-complete", this.markers[0])
      // }
    },
    toggleVis (item) {
      let index = this.markers.indexOf(item)
      if (item.visible === true ) item.visible = false
      else item.visible = true
    },
    focusMarker (item) {
      console.log(item)
      this.center = latLng(item.position.lat, item.position.lng)
      this.$nextTick(() => {
        this.$refs.mapcontrolMap.mapObject.invalidateSize();
      });
    },
    lockMarker (item) {
      if (item.draggable === true) item.draggable = false
      else item.draggable = true
    },
    retrieveMarkers () {
      let entry = this.incidentByEntry(this.entrycode)
      return getMarkers(entry)
    }
  },
  watch: {
    //   foobar (val) {
    //       console.log('asdfasdfasdf')
    //       this.markers = val
    //       console.log(val)
    //       this.$refs.mapcontrolMap.mapObject.invalidateSize();
    //   },
    // mymarker(val) {
    // //   console.log(val);
    // //   this.markers.push(val);
    // //   this.lat = val.position.lat
    // //   this.lng = val.position.lng
    // //   console.log(this.$refs.mapcontrolMap.mapObject)
    // //   this.$nextTick(() => {
    // //       console.log('kolokythia')
    // //     this.$refs.mapcontrolMap.mapObject.invalidateSize();
    // //   });
    //     this.markers = [...val]

    // },
    markers (val) {
      // console.log(val)
    },
    foobar (val) {
      console.log(val)
    },
    mymarker(val) {
        //this.addMarker({latlng: { lat: val.position.lat, lng: val.position.lng}})
        this.markers.push(val)
        // console.log(val)
    },
    mapCenter(val) {
        this.centerUpdate(val)
        this.$nextTick(() => {
          this.$refs.mapcontrolMap.mapObject.invalidateSize();
      });
    }
  },
  created () {
    this.setMarkers()
  },
  mounted() {
    // this.markers = this.retrieveMarkers()
    this.markers = this.foobar
    if (this.markers.length > 0) {
      this.center = latLng(this.markers[0].position.lat, this.markers[0].position.lng)
      this.tmpcenter = this.center
      this.$refs.mapcontrolMap.mapObject.invalidateSize();
    }
    this.$nextTick(() => {
      this.$refs.mapcontrolMap.mapObject.invalidateSize();
    }); 
  },
  computed: {
    ...get("mapform", ["mapCenter"]),
    ...sync("mapform", {
      foobar: "foomarkers",
      mymarker: "marker",
      magicnumber: "magicnumber",
      tmpcenter: "tmpcenter"
    }),
    ...mapGetters({
      incidentByEntry : 'incidentsnew/getIncidentByEntryCode'
    }),
    lalamark () {
      if (!this.foobar) return []
      else return this.foobar
    }
  },
};
</script>
